import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "components/Layout"
import SuccessSection from "components/SuccessSection"

const getImages = graphql`
  {
    bannerSuccess: file(
      relativePath: { eq: "fitfarm-omaha-contact-us-success.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PersonalTraining = () => {
  const imgData = useStaticQuery(getImages)

  return (
    <Layout>
      <article>
        <SuccessSection sectionImage={imgData.bannerSuccess} />
      </article>
    </Layout>
  )
}

export default PersonalTraining
